'use client'

import { useGetV1ContextsAccount } from '@/api/client-hooks'
import { PageHeader } from '@/components/common/page-header'
import { Section } from '@/components/common/section'
import { routes } from '@/constants/routes'
import useIsMAO from '@/hooks/accounts/useIsMAO'
import { Grid } from '@radix-ui/themes'
import { useTranslations } from 'next-intl'
import { GuideTeaser } from './_components/guide-teaser'

type GuideId =
  | 'questionnaire'
  | 'invitationMail'
  | 'account'
  | 'profile'
  | 'manualSendOut'
  | 'shareableLink'
  | 'results'
  | 'comments'
  | 'lists'

type GuideProps = {
  id: GuideId
  route: string
}

interface GuideListProps {
  guides: GuideProps[]
}

const GuideList = ({ guides }: GuideListProps) => {
  const homeT = useTranslations('home')

  return (
    <Grid columns={{ initial: '1', sm: '2', md: '3', lg: '4' }} gap="4">
      {guides.map((guide) => {
        return (
          <GuideTeaser
            key={guide.id}
            buttonLabel={homeT('guideButtonLabel')}
            description={homeT(`guide.${guide.id}.description`)}
            href={homeT(`guide.${guide.id}.link`)}
            title={homeT(`guide.${guide.id}.title`)}
          />
        )
      })}
    </Grid>
  )
}

const filterDefaultSurveys = (guides: GuideProps[]) => {
  const surveyRelated = [
    routes.settingsCxm(),
    routes.cxmComments({ surveyId: '' }),
    routes.cxmLists({ surveyId: '' }),
    routes.cxmResults({ surveyId: '' }),
  ] as string[]

  return guides.filter((guide) => !surveyRelated.includes(guide.route))
}

export function Home() {
  const homeT = useTranslations('home')
  const contextsAccountQuery = useGetV1ContextsAccount()
  const hideDefaultSurveys =
    contextsAccountQuery.data?.settings?.hideDefaultSurveys
  const isMAO = useIsMAO()

  let setupGuides: GuideProps[] = [
    { id: 'questionnaire', route: routes.settingsCxm() },
    { id: 'invitationMail', route: routes.settingsCxm() },
    { id: 'account', route: routes.settingsAccount() },
    { id: 'profile', route: routes.profile() },
  ]

  let featureGuides: GuideProps[] = [
    { id: 'manualSendOut', route: routes.settingsCxm() },
    { id: 'shareableLink', route: routes.settingsCxm() },
    { id: 'results', route: routes.cxmResults({ surveyId: '' }) },
    { id: 'comments', route: routes.cxmComments({ surveyId: '' }) },
  ]

  setupGuides = hideDefaultSurveys
    ? filterDefaultSurveys(setupGuides)
    : setupGuides
  featureGuides = hideDefaultSurveys
    ? filterDefaultSurveys(featureGuides)
    : featureGuides
  if (!isMAO) {
    featureGuides.push({
      id: 'lists',
      route: routes.cxmLists({ surveyId: '' }),
    })
  }

  return (
    <>
      <PageHeader title={homeT('pageTitle')} text={homeT('pageDescription')} />

      {setupGuides.length > 0 && (
        <Section
          title={homeT('setupGuides.title')}
          text={homeT('setupGuides.description')}
        >
          <GuideList guides={setupGuides} />
        </Section>
      )}

      {featureGuides.length > 0 && (
        <Section
          title={homeT('featureGuides.title')}
          text={homeT('featureGuides.description')}
        >
          <GuideList guides={featureGuides} />
        </Section>
      )}
    </>
  )
}
