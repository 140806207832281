import type {
  CommonApiDtOsSurveyAccountSurveyDto,
  CoreDtOsAccountAccountSurveyOverviewDtoSurvey,
} from '@/api/models'
import { defaultLocale } from '@/i18n/config'

export function getDefaultSurveyLanguage(
  survey:
    | CommonApiDtOsSurveyAccountSurveyDto
    | CoreDtOsAccountAccountSurveyOverviewDtoSurvey
    | undefined,
) {
  const surveyLanguages = survey?.surveyLanguages ?? []
  const language = surveyLanguages.find((language) => language.isDefault)
  return language?.languageName?.languageCode ?? defaultLocale
}
